export const BRAND_COLOR = '#f0b90b';
export const COLOR_GREEN = '#0ecb81';
export const COLOR_RED = '#f6465d';
export const COLOR_YELLOW = '#ffc20c';
export const KAERU_RSI_COLOR = '#cfc137';
export const KAERU_SMA_COLOR = '#7d208d';
export const KAERU_BUY_SIGNAL_COLOR = '#189160';
export const KAERU_SELL_SIGNAL_COLOR = '#D23247';

// Функция для преобразования HEX в HSL
function hexToHSL(hex: string): [number, number, number] {
  hex = hex.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number,
    s: number,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h = Math.round(h! * 60);
  }

  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return [h!, s, l];
}

// Функция для преобразования HSL в HEX
function hslToHex(h: number, s: number, l: number): string {
  h /= 360;
  s /= 100;
  l /= 100;
  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // Achromatic (gray)
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// Функция для генерации набора цветов
export function generateColorSet(baseColorHex: string, size: number = 10): string[] {
  const [h, s, l] = hexToHSL(baseColorHex);

  const hueStep = 360 / size;

  // Генерация вариаций цвета
  const variations: [number, number, number][] = Array.from({ length: size }).map((_, index) => [
    h + index * hueStep,
    s,
    l,
  ]);

  // Преобразование вариаций HSL в HEX
  return variations.map(([h, s, l]) =>
    hslToHex(h % 360, Math.max(0, Math.min(100, s)), Math.max(0, Math.min(100, l)))
  );
}

export function changeSaturation(hex: string, delta: number): string {
  const [h, s, l] = hexToHSL(hex);
  return hslToHex(h, s + delta, l);
}
export function changeLightness(hex: string, delta: number): string {
  const [h, s, l] = hexToHSL(hex);
  return hslToHex(h, s, l + delta);
}
