import { useMemo } from 'react';
import { ArrowUpNarrowWide, JapaneseYen } from 'lucide-react';

import { StrategyTypeEnum } from '@/types';

type StrategyTypeLabelProps = { type: StrategyTypeEnum };
export const StrategyTypeLabel = ({ type }: StrategyTypeLabelProps) => {
  const color = useMemo(() => {
    if (type == StrategyTypeEnum.Grid) return 'text-brand';
    if (type == StrategyTypeEnum.Kaeru) return 'text-blue';
  }, [type]);

  return (
    <div className={`flex items-center gap-1 ${color}`}>
      <span className="text-sm">{type}</span>
      {type == StrategyTypeEnum.Grid ? <ArrowUpNarrowWide className="size-5" /> : null}
      {type == StrategyTypeEnum.Kaeru ? <JapaneseYen className="size-5" /> : null}
    </div>
  );
};
