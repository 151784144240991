import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

type ConfirmStrategyRestartProps = {
  strategyId: number;
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmCloseKaeruTrade = ({
  strategyId,
  onCancel,
  onConfirm,
}: ConfirmStrategyRestartProps) => {
  return (
    <AlertDialog open onCancel={onCancel}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Close trade?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to close Kaeru ({strategyId}) trade manually?
            <br />
            It might affect on potential return
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Close trade</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
