import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import {
  calculateStrategy,
  getActiveStrategies,
  getStoppedStrategies,
  getStrategyById,
  getTerminatedStrategies,
  manuallyCloseKaeruTrade,
  restartStrategies,
  startStrategy,
  stopStrategy,
  terminateAllStrategies,
  updateStrategy,
} from '@/modules/api';
import {
  AnyStrategy,
  AnyStrategyCalculatedParams,
  StartAnyStrategyParams,
  StrategyStopModeEnum,
  StrategyTypeEnum,
  UpdateAnyStrategyParams,
} from '@/types';

import { useNotification } from './useNotification';
import { OPEN_ORDERS_REQUEST } from './useOrders';
import { STATUS_REQUEST } from './useStatistic';

export const STRATEGY_REQUEST_UPDATE_DELAY = 8_000;
const STRATEGY_STALE_TIME = 120_000;

export const STRATEGY_INFO_REQUEST = 'strategy-info-request';
export const useStrategy = (strategyId?: number) => {
  const { isAuth } = useAuth();

  const strategyInfoRequest = useQuery({
    queryKey: [STRATEGY_INFO_REQUEST, strategyId],
    queryFn: async () => await getStrategyById(strategyId!),
    select: data => data.data,
    enabled: isAuth && Boolean(strategyId),
    staleTime: STRATEGY_STALE_TIME,
    refetchInterval: STRATEGY_STALE_TIME,
  });

  const strategyStartParams = useMemo(
    () => strategyInfoRequest.data?.startParams as AnyStrategyCalculatedParams,
    [strategyInfoRequest.data]
  );
  const strategyWorkingStat = useMemo(
    () => strategyInfoRequest.data?.strategy as AnyStrategy,
    [strategyInfoRequest.data]
  );

  return { strategyInfoRequest, strategyStartParams, strategyWorkingStat };
};

export const ACTIVE_STRATEGIES_REQUEST = 'active-strategies-request';
export const useActiveStrategies = () => {
  const { isAuth } = useAuth();

  const activeStrategiesRequest = useQuery({
    queryKey: [ACTIVE_STRATEGIES_REQUEST],
    queryFn: async () => await getActiveStrategies(),
    select: data => data.data.activeStrategies as AnyStrategy[],
    enabled: isAuth,
    staleTime: STRATEGY_STALE_TIME,
    refetchInterval: STRATEGY_STALE_TIME,
  });

  const activeStrategies = useMemo(
    () => activeStrategiesRequest.data || [],
    [activeStrategiesRequest.data]
  );

  return { activeStrategies, activeStrategiesRequest };
};

export const TERMINATED_STRATEGIES_REQUEST = 'terminated-strategies-request';
export const useTerminatedStrategies = () => {
  const { isAuth } = useAuth();

  const terminatedStrategiesRequest = useQuery({
    queryKey: [TERMINATED_STRATEGIES_REQUEST],
    queryFn: async () => await getTerminatedStrategies(),
    select: data => data.data.terminatedStrategies as AnyStrategy[],
    enabled: isAuth,
    staleTime: STRATEGY_STALE_TIME,
    refetchInterval: STRATEGY_STALE_TIME,
  });

  const terminatedStrategies = useMemo(
    () => terminatedStrategiesRequest.data || [],
    [terminatedStrategiesRequest.data]
  );

  return { terminatedStrategies, terminatedStrategiesRequest };
};

export const STOPPED_STRATEGIES_REQUEST = 'stopped-strategies-request';
export const useStoppedStrategies = () => {
  const { isAuth } = useAuth();

  const stoppedStrategiesRequest = useQuery({
    queryKey: [STOPPED_STRATEGIES_REQUEST],
    queryFn: async () => await getStoppedStrategies(),
    select: data => data.data.stoppedStrategies as AnyStrategy[],
    enabled: isAuth,
    staleTime: Infinity,
  });

  const stoppedStrategies = useMemo(
    () => stoppedStrategiesRequest.data || [],
    [stoppedStrategiesRequest.data]
  );

  return {
    stoppedStrategiesRequest,
    stoppedStrategies,
  };
};

export const useAllStrategies = () => {
  const { activeStrategies, activeStrategiesRequest } = useActiveStrategies();
  const { terminatedStrategies, terminatedStrategiesRequest } = useTerminatedStrategies();
  const { stoppedStrategies, stoppedStrategiesRequest } = useStoppedStrategies();

  const allStrategies = useMemo<AnyStrategy[]>(
    () => [...activeStrategies, ...terminatedStrategies, ...stoppedStrategies],
    [activeStrategies, terminatedStrategies, stoppedStrategies]
  );

  return {
    activeStrategies,
    terminatedStrategies,
    stoppedStrategies,
    allStrategies,
    isLoading:
      activeStrategiesRequest.isLoading ||
      terminatedStrategiesRequest.isLoading ||
      stoppedStrategiesRequest.isLoading,
  };
};

const START_STRATEGY_MUTATION = 'start-strategy-mutation';
const CALCULATE_STRATEGY_MUTATION = 'calculate-strategy-mutation';
const STOP_STRATEGY_MUTATION = 'stop-strategy-mutation';
const UPDATE_STRATEGY_MUTATION = 'update-strategy-mutation';
const RESTART_STRATEGIES_MUTATION = 'restart-strategy-mutation';
export const useStrategyActions = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();

  const queryClient = useQueryClient();

  const restartStrategiesMutation = useMutation({
    mutationKey: [RESTART_STRATEGIES_MUTATION],
    mutationFn: async (ids: number[]) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await restartStrategies(ids);
      info({ title: `Strategy ${ids} restarting...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const startStrategyMutation = useMutation({
    mutationKey: [START_STRATEGY_MUTATION],
    mutationFn: async ({
      type,
      params,
    }: {
      type: StrategyTypeEnum;
      params: StartAnyStrategyParams;
    }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await startStrategy(type, params);
      info({ title: `Starting ${type} strategy...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const stopStrategyMutation = useMutation({
    mutationKey: [STOP_STRATEGY_MUTATION],
    mutationFn: async ({ id, stopMode }: { id: number; stopMode: StrategyStopModeEnum }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await stopStrategy(id, stopMode);
      info({ title: `Strategy ${id} stopping...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const updateStrategyMutation = useMutation({
    mutationKey: [UPDATE_STRATEGY_MUTATION],
    mutationFn: async ({ id, params }: { id: number; params: UpdateAnyStrategyParams }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await updateStrategy(id, params);
      info({ title: `Strategy (${id}) updated` });
    },
    onSuccess: (_, { id }) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST, id] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  const calculateStrategyMutation = useMutation({
    mutationKey: [CALCULATE_STRATEGY_MUTATION],
    mutationFn: async ({
      type,
      params,
    }: {
      type: StrategyTypeEnum;
      params: StartAnyStrategyParams;
    }) => calculateStrategy(type, params),
    onError: handleError,
  });

  return {
    restartStrategiesMutation,
    startStrategyMutation,
    calculateStrategyMutation,
    stopStrategyMutation,
    updateStrategyMutation,
  };
};

const MANUALLY_CLOSE_KAERU_TRADE_MUTATION = 'manually-close-kaeru-trade-mutation';
export const useKaeruStrategyActions = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();
  const queryClient = useQueryClient();

  const manuallyCloseKaeruTradeMutation = useMutation({
    mutationKey: [MANUALLY_CLOSE_KAERU_TRADE_MUTATION],
    mutationFn: async (strategyId: number) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await manuallyCloseKaeruTrade(strategyId);
      info({ title: `Trade closed for Kaeru Strategy (${strategyId})` });
    },
    onSuccess: (_, strategyId) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST, strategyId] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  return { manuallyCloseKaeruTradeMutation };
};

const TERMINATE_ALL_STRATEGIES_MUTATION = 'terminate-all-strategies-mutation';
export const useTerminateAllStrategies = () => {
  const { handleError, info } = useNotification();
  const { isAuth } = useAuth();
  const queryClient = useQueryClient();

  const terminateAllMutation = useMutation({
    mutationKey: [TERMINATE_ALL_STRATEGIES_MUTATION],
    mutationFn: async () => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await terminateAllStrategies();
      info({ title: `Strategies terminating...` });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ACTIVE_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [TERMINATED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STOPPED_STRATEGIES_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [STRATEGY_INFO_REQUEST] });
      }, STRATEGY_REQUEST_UPDATE_DELAY);
    },
    onError: handleError,
  });

  return terminateAllMutation;
};
