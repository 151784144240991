import { Time } from 'lightweight-charts';

import { Kline } from '@/types';

type DataTime = Time | Date | string | number;
export type KlineData = Omit<Kline, 'openTs' | 'closeTs'> & { time: DataTime };
export type LineSeriesData = { time: DataTime; value: number };

export type DatasetData = LineSeriesData | KlineData;

// export enum DatasetTypeEnum {
//   Area = 'area',
//   Line = 'line',
//   Candlestick = 'candlestick',
//   Histogram = 'histogram',
// }

// export type Dataset = {
//   data: DatasetData[];
//   type: DatasetTypeEnum;
//   colors?: Record<string, any>;
// };

export const defaultChartColors = {
  backgroundColor: 'white',
  lineColor: '#d2d2d2',
  textColor: 'black',
  areaTopColor: '#d2d2d2',
  areaBottomColor: 'rgba(41, 98, 255, 0.28)',
  crosshairLabelColor: '#252934',
};

export const defaultDarkChartColors = {
  backgroundColor: '#161a1e', // Темный фон
  lineColor: '#2b3139', // Светло-синий цвет для линий
  textColor: '#848e9c', // Белый цвет для текста
  areaTopColor: '#2b3139', // Светло-синий цвет для верхней области
  areaBottomColor: 'rgba(130, 177, 255, 0.28)', // Прозрачный светло-синий цвет для нижней области
  crosshairLabelColor: '#252934',
};
