import { Order, PositionSideEnum, SideEnum, StrategyBase, StrategyStateEnum } from '@/types';

export const getReadableError = (err: any) => {
  let errorMsg = err?.message || 'Something went wrong';
  if (err?.response?.data?.detail) {
    const { detail } = err.response.data;
    if (Array.isArray(detail)) {
      errorMsg = detail[0]?.msg;
    } else {
      errorMsg = detail;
    }
  }

  return errorMsg;
};

const getAliveDays = (elapsedTs: number) => {
  const elapsedDays = Math.floor(elapsedTs / 1000 / (60 * 60 * 24));
  return elapsedDays;
};

const getAliveHours = (elapsedTs: number) => {
  const elapsedHrs = Math.floor(elapsedTs / 1000 / (60 * 60));
  return elapsedHrs;
};

const getAliveMins = (elapsedTs: number) => {
  const elapsedHrs = Math.floor(elapsedTs / 1000 / 60);
  return elapsedHrs;
};

export const getReadableDuration = (elapsedTs: number) => {
  const days = getAliveDays(elapsedTs);
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  const hrs = getAliveHours(elapsedTs);
  if (hrs > 0) {
    return `${hrs} hour${hrs > 1 ? 's' : ''}`;
  }
  const mins = getAliveMins(elapsedTs);
  return `${mins} min${mins > 1 ? 's' : ''}`;
};

export const getStrategyAlivePeriod = (
  strategy: Pick<StrategyBase, 'state' | 'startTimestamp' | 'stopTimestamp'>
) => {
  const startTs = strategy.startTimestamp;
  const endTs = Math.max(
    strategy.state != StrategyStateEnum.Active ? strategy.stopTimestamp || 0 : getNowTimestamp(),
    startTs
  );

  const elapsedTs = endTs - startTs;
  return getReadableDuration(elapsedTs);
};

export const getNowTimestamp = () => {
  return new Date().getTime();
};

export const round = (value: number, decimals: number = 0) => {
  return Math.round(value * 10 ** decimals) / 10 ** decimals;
};

function roundToSignificantFigures(num: number, sig: number) {
  if (num === 0) return 0;
  const multiplier = Math.pow(10, sig - Math.floor(Math.log10(Math.abs(num))) - 1);
  return Math.round(num * multiplier) / multiplier;
}

export function formatNumber(num: number, decimals?: number) {
  if (num < 1 && num > -1 && decimals !== undefined)
    return roundToSignificantFigures(num, decimals);

  const fixedNum = decimals !== undefined ? num.toFixed(decimals) : num.toString();
  const parts = fixedNum.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length > 1) {
    // remove ending 000...
    parts[1] = parseFloat(fixedNum).toString().split('.')[1];
  }

  return parts.filter(Boolean).join('.');
}

export const calculateLevels = (minPrice: number, maxPrice: number, step: number) => {
  const levels: number[] = [];
  let currentPrice = minPrice;
  const roundTo = minPrice < 1 ? 5 : 3;
  while (currentPrice <= maxPrice) {
    const priceS = round(currentPrice, roundTo);
    levels.push(priceS);
    currentPrice += priceS * step;
  }

  return levels;
};

export const isReduceOrder = (order: Pick<Order, 'positionSide' | 'side'>) => {
  return (
    (order.positionSide === PositionSideEnum.LONG && order.side === SideEnum.SELL) ||
    (order.positionSide === PositionSideEnum.SHORT && order.side === SideEnum.BUY)
  );
};
export const getOrderMode = (order: Pick<Order, 'positionSide' | 'side'>) =>
  `${isReduceOrder(order) ? 'Close' : 'Open'} ${order.positionSide === PositionSideEnum.LONG ? 'Long' : 'Short'}`;

export const getCookie = (key: string) => {
  const cookieArr = document.cookie.split(';');

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');

    // Удаляем пробелы в начале и конце имени куки и сравниваем с искомым именем
    if (key === cookiePair[0].trim()) {
      // Возвращаем значение куки, декодируя его
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};

export const eraseCookie = (key: string) => {
  document.cookie = key + '=; Max-Age=-99999999;';
};

export const formatDate = (
  date: Date,
  { withYear = false, withTime = true }: { withYear?: boolean; withTime?: boolean } = {}
) =>
  date.toLocaleDateString('en-US', {
    year: withYear ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: withTime ? 'numeric' : undefined,
    minute: withTime ? 'numeric' : undefined,
    hour12: false,
  });
