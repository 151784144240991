import { FC } from 'react';
import {
  CircleDollarSign,
  DollarSign,
  Pin,
  Scale,
  Sigma,
  Skull,
  WalletMinimal,
} from 'lucide-react';

import { CenteredSpinner } from '@/components/ui/spinner';
import { useAccount, useBalance, usePositions } from '@/hooks/usePositions';
import { useStatus } from '@/hooks/useStatistic';
import { formatNumber, round } from '@/lib/common';

export const AccountMarginRatio = () => {
  const {
    tradePositionsValue,
    positionsRequest: { isLoading: isPositionsLoading },
  } = usePositions();
  const {
    equityBalance,
    availableBalance,
    balanceRequest: { isLoading: isBalanceLoading },
  } = useBalance();
  const {
    marginRatio,
    account,
    accountRequest: { isLoading: isAccountLoading },
  } = useAccount();
  const {
    marginRatioCalculations,
    statusRequest: { isLoading: isStatusLoading },
  } = useStatus();

  const isLoading = isPositionsLoading || isBalanceLoading || isAccountLoading;
  const actualTradeLeverage = equityBalance ? tradePositionsValue / equityBalance : 0;

  return (
    <div className="absolute inset-0 flex size-full flex-col overflow-hidden p-0">
      {isLoading ? <CenteredSpinner /> : null}

      <p className="text-section-heading px-3 pt-1">Account</p>

      <div className="flex flex-col gap-1 p-3">
        <FieldValue
          label={
            <div className="flex items-center">
              <Sigma className="mr-1 size-5" /> Margin Ratio:
            </div>
          }
          isWarning={marginRatio > 10}
        >
          <div className="flex items-center">
            {!isAccountLoading ? round(marginRatio, 2) : '---'}% (
            <Skull className="mx-1 size-4 text-error" />
            {!isStatusLoading
              ? `${round((marginRatioCalculations?.liquidationPercentDelta || 0) * 100)}%`
              : '---'}
            )
          </div>
        </FieldValue>

        <FieldValue
          label={
            <div className="flex items-center">
              <DollarSign className="mr-1 size-5" /> Account Equity:
            </div>
          }
        >
          {equityBalance !== undefined ? formatNumber(equityBalance, 2) : '---'} USD
        </FieldValue>

        <FieldValue
          label={
            <div className="flex items-center">
              <CircleDollarSign className="mr-1 size-5" /> Available Balance:
            </div>
          }
        >
          {availableBalance !== undefined ? formatNumber(availableBalance, 2) : '---'} USD
        </FieldValue>

        <FieldValue
          label={
            <div className="flex items-center">
              <WalletMinimal className="mr-1 size-5" /> Unrealized Profit:
            </div>
          }
        >
          {account ? formatNumber(account.totalUnrealizedProfit, 2) : '---'} USD
        </FieldValue>

        <FieldValue
          label={
            <div className="flex items-center">
              <Pin className="mr-1 size-5" /> Trade Positions:
            </div>
          }
        >
          {!isPositionsLoading ? formatNumber(tradePositionsValue, 2) : '---'} USD
        </FieldValue>

        <FieldValue
          label={
            <div className="flex items-center">
              <Scale className="mr-1 size-5" /> Trade Leverage:
            </div>
          }
          isWarning={actualTradeLeverage > 3}
        >
          {!isPositionsLoading && !isBalanceLoading ? formatNumber(actualTradeLeverage, 3) : '---'}{' '}
          X
        </FieldValue>
      </div>
    </div>
  );
};

type FieldValueProps = {
  label: React.ReactNode;
  value?: string;
  children?: React.ReactNode;
  isWarning?: boolean;
};
const FieldValue: FC<FieldValueProps> = ({ label, value, isWarning, children }) => {
  return (
    <div className="relative px-1">
      {isWarning ? <div className="absolute inset-0 bg-destructive opacity-50"></div> : null}
      <div className="relative flex items-center whitespace-nowrap">
        <p className="w-[160px] shrink-0 text-sm font-normal text-secondary-foreground">{label}</p>
        <p className="font-medium">{children || value}</p>
      </div>
    </div>
  );
};
