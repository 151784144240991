import { useCallback, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { usePositions } from '@/hooks/usePositions';
import { formatNumber, round } from '@/lib/common';

type SymbolsTotalParameterChartProps = {
  data: Record<string, number>;
  label: string;
  isCurrency?: boolean;
};
export const SymbolsTotalParameterChart = ({
  data,
  label,
  isCurrency = false,
}: SymbolsTotalParameterChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartConfig = useMemo(
    () => ({
      value: {
        label,
      },
      label: {
        color: 'var(--foreground)',
      },
    }),
    [label]
  );

  const format = useCallback(
    (val: number) => (isCurrency ? formatNumber(val, 2) + '$' : round(val, 2)),
    [isCurrency]
  );
  const chartData = useMemo(
    () =>
      Object.entries(data)
        .sort((a, b) => b[1] - a[1])
        .map(([symbol, value]) => ({ symbol, value: round(value, 2) }))
        .map(d => ({ ...d, fill: getSymbolColor(d.symbol) })),
    [data, getSymbolColor]
  );

  const totalValue = useMemo(
    () => Object.values(data).reduce((acc, value) => acc + value, 0),
    [data]
  );
  const totalAbsoluteValue = useMemo(
    () => Object.values(data).reduce((acc, value) => acc + Math.abs(value), 0),
    [data]
  );

  const tooltipValueFormatter = useCallback(
    (val: number) => {
      const percent = totalAbsoluteValue ? round((val / totalAbsoluteValue) * 100, 2) : 0;

      return (
        <div className="ml-2 flex gap-2 font-mono font-medium tabular-nums">
          <span>{percent}%</span>
          <span className="text-muted-foreground">{format(val)}</span>
        </div>
      );
    },
    [format, totalAbsoluteValue]
  );

  const renderCustomizedLabel = useCallback((props: any) => {
    const { y, height, width, value, offset, fontSize, formatter, className } = props;

    return (
      <g>
        <text
          x={offset}
          y={y}
          width={width}
          fontSize={fontSize}
          className={className}
          textAnchor="start"
          dominantBaseline="middle"
        >
          <tspan dy={height / 2}>{formatter(value)}</tspan>
        </text>
      </g>
    );
  }, []);

  return (
    <Card className="flex size-full flex-col p-4">
      <CardHeader className="p-0">
        <CardTitle className="text-md">
          {label}: {format(totalValue)}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex grow flex-col p-0 pt-2">
        <ChartContainer config={chartConfig} className="h-full">
          <BarChart accessibilityLayer data={chartData} layout="vertical" margin={{}} height={100}>
            <CartesianGrid horizontal={false} />
            <YAxis
              dataKey="symbol"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={value => value.slice(0, 3)}
              hide
            />
            <XAxis dataKey="value" type="number" hide />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent indicator="line" valueFormatter={tooltipValueFormatter} />
              }
            />

            <Bar dataKey="value" layout="vertical" radius={4}>
              <LabelList
                content={renderCustomizedLabel}
                dataKey="symbol"
                position="insideLeft"
                offset={8}
                className="fill-[--color-label]"
                fontSize={11}
                formatter={(val: string) =>
                  `${val} ${totalAbsoluteValue ? round((data[val] / totalAbsoluteValue) * 100, 2) : 0}%`
                }
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};
