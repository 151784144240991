import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function onPseudoCn(pseudo: string, classes: string[]) {
  return classes.map(c => `${pseudo}:${c}`);
}

const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const camelCaseKey = toCamelCase(key);
        acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        return acc;
      },
      {} as Record<string, any>
    );
  }
  return obj;
};

function camelToUnderscore(key: string) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function convertKeysToUnderscore(obj: any): any {
  if (obj && Array.isArray(obj)) {
    return obj.map(item => convertKeysToUnderscore(item));
  } else if (obj && obj.constructor === Object) {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
      // eslint-disable-next-line
      if (obj.hasOwnProperty(key)) {
        const newKey = camelToUnderscore(key);
        newObj[newKey] = convertKeysToUnderscore(obj[key]);
      }
    }
    return newObj;
  }

  return obj;
}

export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

const isObject = (object: any) => {
  return object != null && typeof object === 'object';
};

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};
