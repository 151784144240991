import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
  SeriesMarker,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  UTCTimestamp,
} from 'lightweight-charts';
import { ChevronsUpDown, Maximize2 } from 'lucide-react';

import { KlineData } from '@/components/blocks/Chart/commonChartProps';
import { PriceChart, PriceChartComponentHandle } from '@/components/blocks/Chart/PriceChart';
import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Toggle } from '@/components/ui/toggle';
import { useDashboardContext } from '@/context/DashboardContext';
import { useOpenOrders } from '@/hooks/useOrders';
import { useKlines } from '@/hooks/usePositions';
import { useStrategy } from '@/hooks/useStrategies';
import {
  KAERU_BUY_SIGNAL_COLOR,
  KAERU_RSI_COLOR,
  KAERU_SELL_SIGNAL_COLOR,
  KAERU_SMA_COLOR,
} from '@/lib/colors';
import { formatNumber } from '@/lib/common';
import {
  isGridCalculatedParams,
  isGridStrategy,
  isKaeruStartStrategy,
  isKaeruStrategy,
  KaeruSignalEnum,
  KlineIntervalEnum,
} from '@/types';

export const DashboardChart = () => {
  const { activeSymbol, interval, activeStrategy, selectSymbol, selectInterval } =
    useDashboardContext();
  const [showGrid, toggleShowGrid] = useState<boolean>(false);
  const [showKaeru, toggleShowKaeru] = useState<boolean>(false);
  const [showOrders, toggleShowOrders] = useState<boolean>(false);
  const chartRef = useRef<PriceChartComponentHandle>(null);

  const {
    strategyStartParams,
    strategyInfoRequest: { isLoading: isStrategyLoading },
  } = useStrategy(activeStrategy?.id);
  const { klines, klinesRequest } = useKlines(activeSymbol, interval);
  const { orders } = useOpenOrders(activeSymbol);

  const isKlinesLoading = useMemo(
    () => klinesRequest.isFetching && !klinesRequest.isRefetching,
    [klinesRequest]
  );

  const isPriceDataLoading = useMemo(
    () => isStrategyLoading || isKlinesLoading,
    [isStrategyLoading, isKlinesLoading]
  );

  const currentInterval = useRef<KlineIntervalEnum>(interval);
  const currentStrategyId = useRef<number>();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    const timer = setTimeout(() => {
      currentInterval.current = interval;
      currentStrategyId.current = activeStrategy?.id;

      forceUpdate();
    }, 100);

    return () => clearTimeout(timer);
  }, [activeStrategy, interval]);

  const isIntervalChanged = currentInterval.current !== interval;
  const isStrategyChanged = currentStrategyId.current !== activeStrategy?.id;

  const isChanged = isIntervalChanged || isStrategyChanged;

  const isChartLayersLoading = isPriceDataLoading || isChanged;

  const klinesData = useMemo<KlineData[]>(() => {
    return (klines || []).map(({ openTs, closeTs, ...values }) => ({
      ...values,
      time: Math.round(openTs / 1000),
    }));
  }, [klines]);

  const gridLines = useMemo(() => {
    if (showGrid && strategyStartParams && isGridCalculatedParams(strategyStartParams))
      return strategyStartParams.levels;
    return undefined;
  }, [strategyStartParams, showGrid]);

  const ordersLines = useMemo(() => {
    if (showOrders && orders) return orders;
    return undefined;
  }, [showOrders, orders]);

  const kaeruIndicatorsData = useMemo(() => {
    if (showKaeru && strategyStartParams && isKaeruStartStrategy(strategyStartParams)) {
      return {
        rsi: strategyStartParams.backtest.rsi,
        sma: strategyStartParams.backtest.sma,
      };
    }
    return undefined;
  }, [showKaeru, strategyStartParams]);

  const markers = useMemo<SeriesMarker<UTCTimestamp>[] | undefined>(() => {
    if (showKaeru && strategyStartParams && isKaeruStartStrategy(strategyStartParams)) {
      return strategyStartParams.backtest.signals
        .map(({ time, signal, openPrice }) => ({
          time: Math.floor(time / 1000) as UTCTimestamp,
          position: (signal === KaeruSignalEnum.SELL_SIGNAL
            ? 'aboveBar'
            : 'belowBar') as SeriesMarkerPosition,
          shape: (signal === KaeruSignalEnum.SELL_SIGNAL
            ? 'arrowDown'
            : 'arrowUp') as SeriesMarkerShape,
          color:
            signal === KaeruSignalEnum.SELL_SIGNAL
              ? KAERU_SELL_SIGNAL_COLOR
              : KAERU_BUY_SIGNAL_COLOR,
          text: formatNumber(openPrice).toString(),
        }))
        .slice(-100);
    }
    return undefined;
  }, [showKaeru, strategyStartParams]);

  const indicators = useMemo(() => {
    if (kaeruIndicatorsData) {
      return [
        // RSI
        {
          type: 'line',
          params: { color: KAERU_RSI_COLOR, lineWidth: 2, lineType: 2 },
          data: kaeruIndicatorsData.rsi
            .filter(({ value }) => value != null)
            .map(indicator => ({
              ...indicator,
              time: Math.floor(indicator.time / 1000) as UTCTimestamp,
            })),
        },
        // SMA
        {
          type: 'line',
          params: { color: KAERU_SMA_COLOR, lineWidth: 2, lineType: 2 },
          data: kaeruIndicatorsData.sma
            .filter(({ value }) => value != null)
            .map(indicator => ({
              ...indicator,
              time: Math.floor(indicator.time / 1000) as UTCTimestamp,
            })),
        },
      ];
    }
    return undefined;
  }, [kaeruIndicatorsData]);

  useEffect(() => {
    if (!activeStrategy) {
      toggleShowKaeru(false);
      toggleShowGrid(false);
    } else if (isGridStrategy(activeStrategy)) {
      toggleShowKaeru(false);
    } else if (isKaeruStrategy(activeStrategy)) {
      toggleShowGrid(false);
    }
  }, [activeStrategy]);

  const handleShowKaeru = useCallback(
    (val: boolean) => {
      toggleShowKaeru(val);
      if (val) {
        if (activeStrategy && isKaeruStrategy(activeStrategy)) {
          selectInterval(activeStrategy.timeframe);
        }
      }
    },
    [activeStrategy, selectInterval]
  );

  const handleFit = useCallback(() => chartRef.current?.scaleFit(), []);

  return (
    <div className="absolute inset-0 flex size-full flex-col overflow-hidden p-0">
      {isPriceDataLoading ? <CenteredSpinner /> : null}

      <div className="absolute left-3 top-3 z-10 inline-flex overflow-hidden rounded-xl border border-border bg-background">
        <SymbolSearchSelect
          className="rounded-none border-none"
          size="sm"
          symbol={activeSymbol}
          onSetSymbol={selectSymbol}
        />

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="sm"
              variant="outline"
              disabled={showKaeru}
              className="rounded-none border-x border-y-0 border-border"
            >
              {interval}
              <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-16" align="start">
            <DropdownMenuRadioGroup
              value={interval}
              onValueChange={val => selectInterval(val as KlineIntervalEnum)}
            >
              {Object.values(KlineIntervalEnum).map(value => (
                <DropdownMenuRadioItem key={value} value={value}>
                  {value}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        {activeStrategy && isGridStrategy(activeStrategy) ? (
          <Toggle
            size="sm"
            disabled={!activeStrategy}
            className="rounded-none"
            pressed={showGrid}
            onPressedChange={() => toggleShowGrid(val => !val)}
          >
            grid
          </Toggle>
        ) : null}

        {activeStrategy && isKaeruStrategy(activeStrategy) ? (
          <Toggle
            size="sm"
            disabled={!activeStrategy}
            className="rounded-none"
            pressed={showKaeru}
            onPressedChange={() => handleShowKaeru(!showKaeru)}
          >
            kaeru
          </Toggle>
        ) : null}

        <Toggle
          size="sm"
          className="rounded-none border-l border-border"
          pressed={showOrders}
          onPressedChange={() => toggleShowOrders(val => !val)}
        >
          orders
        </Toggle>

        <Button
          variant="ghost"
          size="sm"
          onClick={handleFit}
          className="rounded-none border-l border-border"
        >
          <Maximize2 className="size-4" />
        </Button>
      </div>

      <PriceChart
        ref={chartRef}
        symbol={activeSymbol}
        interval={interval}
        data={klinesData}
        markers={isChartLayersLoading ? undefined : markers}
        grid={isChartLayersLoading ? undefined : gridLines}
        orders={isChartLayersLoading ? undefined : ordersLines}
        indicators={isChartLayersLoading ? undefined : indicators}
      />
    </div>
  );
};
