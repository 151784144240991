import { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { CenteredSpinner } from '@/components/ui/spinner';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TableCaption } from '@/components/ui/table';
import { useDashboardContext } from '@/context/DashboardContext';
import { useTrades } from '@/hooks/useOrders';
import { DAY_PERIOD } from '@/hooks/useStatistic';
import { formatNumber, getNowTimestamp, getOrderMode, isReduceOrder } from '@/lib/common';
import { TradeExtended } from '@/types';

export const TradesList = () => {
  const [fromTs, setFromTs] = useState(getNowTimestamp() - 3 * DAY_PERIOD);
  const {
    trades,
    tradesRequest: { isLoading },
  } = useTrades(fromTs);

  const tradesList = useMemo(
    () => [...trades].sort((a, b) => b.serverTimestamp - a.serverTimestamp),
    [trades]
  );

  const loadMoreTrades = useCallback(() => {
    setFromTs(fromTs - DAY_PERIOD);
  }, [fromTs]);

  return (
    <div className="flex flex-col overflow-auto">
      {isLoading ? <CenteredSpinner /> : null}

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Symbol</TableHead>
            <TableHead>Time</TableHead>
            <TableHead>Side</TableHead>
            <TableHead>Type</TableHead>
            <TableHead isNumeric>Amount</TableHead>
            <TableHead isNumeric>Price</TableHead>
            <TableHead>Strategy Id</TableHead>
            <TableHead isNumeric>Op. profit</TableHead>
            <TableHead isNumeric>Realized PNL</TableHead>
            <TableHead isNumeric>Fee</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tradesList.map((trade, index) => (
            <TradeRow key={`${trade.serverTimestamp}-${index}`} {...trade} />
          ))}
        </TableBody>

        <TableCaption className="pb-4">
          {!trades.length ? (
            <div className="my-8 text-center text-base text-third-foreground">
              {isLoading ? 'Loading' : `Trades not found`}
            </div>
          ) : null}
          <Button variant="outline" onClick={loadMoreTrades}>
            Load more trades
          </Button>
        </TableCaption>
      </Table>
    </div>
  );
};
//   symbol: string;
//   serverTimestamp: number;
//   side: SideEnum; positionSide: PositionSideEnum;
//   type: OrderTypeEnum;

//   qty: number;
//   avgPrice: number;
//   profit?: number;
//   strategyId?: number; level?: number;

type TradeRowProps = TradeExtended;
const TradeRow = ({
  symbol,
  serverTimestamp,
  side,
  positionSide,
  type,
  qty,
  avgPrice,
  realizedProfit,
  strategyId,
  level,
  operationProfit,
  commission,
}: TradeRowProps) => {
  const { selectSymbol } = useDashboardContext();

  const timeString = useMemo(() => {
    return new Date(serverTimestamp || 0).toLocaleString();
  }, [serverTimestamp]);

  const isReduce = useMemo(() => isReduceOrder({ positionSide, side }), [side, positionSide]);
  const mode = useMemo(() => getOrderMode({ positionSide, side }), [side, positionSide]);
  const strategyInfo = useMemo(() => {
    if (strategyId) {
      const strategyData = `${strategyId}`;
      return Number.isFinite(level)
        ? strategyData + ` (level: ${level})`
        : strategyData + ' (Kaeru)';
    }
    return '---';
  }, [strategyId, level]);

  return (
    <TableRow className="font-normal">
      <TableCell className="cursor-pointer" onClick={() => selectSymbol(symbol)}>
        {symbol}
      </TableCell>
      <TableCell className="text-xs">{timeString}</TableCell>
      <TableCell className={isReduce ? 'text-sell' : 'text-buy'}>{mode}</TableCell>
      <TableCell>{type}</TableCell>

      <TableCell isNumeric>{formatNumber(qty)}</TableCell>
      <TableCell isNumeric>{formatNumber(avgPrice, 3)}</TableCell>
      <TableCell>{strategyInfo}</TableCell>
      <TableCell isNumeric>{operationProfit ? formatNumber(operationProfit, 3) : '---'}</TableCell>
      <TableCell isNumeric>{realizedProfit ? formatNumber(realizedProfit, 3) : '---'}</TableCell>
      <TableCell isNumeric>{commission ? formatNumber(commission, 3) : '---'}</TableCell>
    </TableRow>
  );
};
