import { useMemo } from 'react';
import { TokenIcon } from '@token-icons/react';
import { CircleArrowDown, CircleArrowUp, Pencil, ShieldCheck } from 'lucide-react';

import { SetTransferEntryPriceModal } from '@/components/blocks/modals/SetTransferEntryPriceModal/SetTransferEntryPriceModal';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useAccount, useAssetIndex, usePositions, useTransfers } from '@/hooks/usePositions';
import { formatDate, formatNumber, round } from '@/lib/common';
import { cn } from '@/lib/utils';
import { Asset, PositionSideEnum, Transfer } from '@/types';

const calculatePositionEntryPrice = (entryTransfers: Transfer[]) => {
  const { totalValue, totalAmount } = entryTransfers.reduce(
    (acc, transfer) => {
      if (transfer.entryPrice) {
        acc.totalAmount += transfer.income;
        acc.totalValue += transfer.income * transfer.entryPrice;
      }
      return acc;
    },
    { totalValue: 0, totalAmount: 0 }
  );

  return totalAmount ? totalValue / totalAmount : 0;
};

export const AssetsList = () => {
  const { assets } = useAccount();
  const { transfers } = useTransfers();

  const accountAssets = useMemo(
    () =>
      [...assets]
        .sort((a, b) => b.walletBalance - a.walletBalance)
        .filter(a => a.crossWalletBalance || a.crossUnPnl || a.marginBalance),
    [assets]
  );

  const incomingTransfers = useMemo(
    () => [...transfers].filter(t => t.income > 0).sort((a, b) => b.id - a.id),
    [transfers]
  );

  const shortAssets = useMemo(
    () => accountAssets.filter(a => !a.asset.includes('USD') && a.crossWalletBalance > 0.001),
    [accountAssets]
  );

  return (
    <div className="overflow-auto">
      <div className="min-w-fit">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Asset</TableHead>
              <TableHead isNumeric>Wallet balance</TableHead>
              <TableHead isNumeric>Entry price</TableHead>
              {/* <TableHead isNumeric>Unrealized PNL</TableHead> */}
              <TableHead isNumeric>Margin balance</TableHead>
              {/* <TableHead isNumeric>Available balance</TableHead> */}
              <TableHead isNumeric>Asset contribution</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {accountAssets.map((asset, index) => (
              <AssetRow key={`${asset.asset}-${index}`} {...asset} />
            ))}
          </TableBody>
        </Table>

        <div className="mt-8 flex gap-8">
          <div className="w-[540px]">
            <div className="p-2 text-center">Incoming Transfers</div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Asset</TableHead>
                  <TableHead isNumeric>Income</TableHead>
                  <TableHead isNumeric>Entry price</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {incomingTransfers.map(transfer => (
                  <TransferRow key={`transfer-${transfer.id}`} {...transfer} />
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="min-w-[300px] grow">
            <div className="p-2 text-center">Short</div>
            <div className="flex flex-col gap-4">
              {shortAssets.map(asset => (
                <ShortInfo key={asset.asset} asset={asset.asset} longAmount={asset.walletBalance} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type ShortInfoProps = { asset: string; longAmount: number };
const ShortInfo = ({ asset, longAmount }: ShortInfoProps) => {
  const { positionsMap } = usePositions();
  const { transfers } = useTransfers();
  const { assetIndex } = useAssetIndex();

  const symbol = useMemo(() => asset + 'USDT', [asset]);

  const securityRate = useMemo(() => {
    const index = assetIndex ? assetIndex.find(a => a.symbol === asset + 'USD') : undefined;
    return 1 - Math.max(index?.askBuffer || 0, index?.bidBuffer || 0);
  }, [assetIndex, asset]);

  const requiredShortAmount = useMemo(
    () => -1 * longAmount * securityRate,
    [longAmount, securityRate]
  );

  const currentShort = useMemo(
    () => positionsMap[symbol]?.find(p => p.positionSide === PositionSideEnum.SHORT),
    [positionsMap, symbol]
  );

  const longEntryPrice = useMemo(() => {
    const entryTransfers = transfers.filter(t => t.asset === asset && t.income > 0);
    return calculatePositionEntryPrice(entryTransfers);
  }, [transfers, asset]);

  const remainingShort = useMemo(() => {
    const remainingAmount = requiredShortAmount - (currentShort?.positionAmt || 0);

    const entryPrice = Math.abs(
      (Math.abs(requiredShortAmount) * longEntryPrice -
        (currentShort ? Math.abs(currentShort.positionAmt * currentShort.entryPrice) : 0)) /
        remainingAmount
    );

    return { positionAmt: remainingAmount, entryPrice };
  }, [currentShort, requiredShortAmount, longEntryPrice]);

  const coverage = useMemo(
    () => Math.abs(currentShort?.positionAmt || 0) / Math.abs(requiredShortAmount),
    [requiredShortAmount, currentShort]
  );
  // ok если шорт текущий шорт 98% от необходимого
  const isSufficient = useMemo(() => Math.abs(1 - coverage) <= 0.02, [coverage]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <TokenIcon symbol={asset} size="24" variant="branded" />
        {asset} <span className="text-third-foreground">{round(securityRate * 100)}%</span>
      </div>
      <div className="text-sm">
        <span className="mr-1 text-secondary-foreground">Required:</span>
        <span>
          {formatNumber(requiredShortAmount, 3)} {symbol}{' '}
          <span className="text-secondary-foreground">
            (Entry price: {formatNumber(longEntryPrice, 3)}$)
          </span>
        </span>
      </div>
      <div className="text-sm">
        <span className="mr-1 text-secondary-foreground">Current:</span>
        <span>
          {currentShort?.positionAmt} {symbol}{' '}
          <span className="text-secondary-foreground">
            (Entry price: {formatNumber(currentShort?.entryPrice || 0, 3)}$)
          </span>
        </span>
      </div>
      <div>
        {isSufficient ? (
          <span className="flex items-center gap-1 text-green">
            <ShieldCheck /> Fixed:{' '}
            {formatNumber(
              currentShort ? Math.abs(currentShort.positionAmt) * currentShort.entryPrice : 0,
              2
            )}{' '}
            USD ({round(coverage * 100, 2)}%)
          </span>
        ) : (
          <span className="flex items-center gap-1 text-destructive">
            {remainingShort.positionAmt > 0 ? (
              <>
                <CircleArrowDown />
                Reduce short for {formatNumber(remainingShort.positionAmt, 3)} {symbol}
              </>
            ) : (
              <>
                <CircleArrowUp />
                Increase short for {formatNumber(remainingShort.positionAmt, 3)} at price{' '}
                {formatNumber(remainingShort.entryPrice, 3)}
              </>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

type TransferRowProps = Transfer;
const TransferRow = ({ id, asset, income, dt, entryPrice }: TransferRowProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <TableRow className="font-normal">
      <TableCell className="py-2">
        {formatDate(new Date(dt), { withYear: true, withTime: true })}
      </TableCell>
      <TableCell className="flex items-center gap-2 py-2">
        <TokenIcon symbol={asset} size="24" variant="branded" />
        {asset}
      </TableCell>
      <TableCell className="py-2" isNumeric>
        {formatNumber(income, 3)}
      </TableCell>
      <TableCell className="py-2" isNumeric>
        {entryPrice ? (
          <div className="flex items-center justify-end gap-2">
            {formatNumber(entryPrice)}
            <Button className="size-6 p-1" size="xs" variant="ghost" onClick={onOpen}>
              <Pencil />
            </Button>
          </div>
        ) : (
          <Button variant="link" size="xs" className="h-5 p-0 text-orange" onClick={onOpen}>
            Set entry price
          </Button>
        )}
      </TableCell>

      {isOpen ? <SetTransferEntryPriceModal transferId={id} onClose={onClose} /> : null}
    </TableRow>
  );
};

type AssetRowProps = Asset;
const AssetRow = ({
  asset,
  walletBalance,
  marginBalance,
  // unrealizedProfit,
  // availableBalance,
}: AssetRowProps) => {
  const { assetIndex } = useAssetIndex();
  const { transfers } = useTransfers();

  const index = useMemo(
    () => (assetIndex ? assetIndex.find(a => a.symbol === asset + 'USD') : undefined),
    [assetIndex, asset]
  );
  const entryTransfers = useMemo(
    () => transfers.filter(t => t.asset === asset && t.income > 0),
    [transfers, asset]
  );

  const assetContribution = useMemo(
    () => (index ? Math.min(marginBalance * index.bidRate, marginBalance * index.askRate) : 0),
    [index, marginBalance]
  );
  const entryPrice = useMemo(() => calculatePositionEntryPrice(entryTransfers), [entryTransfers]);
  const hasUnsetTransfer = useMemo(() => entryTransfers.some(t => !t.entryPrice), [entryTransfers]);

  return (
    <TableRow className="font-normal">
      <TableCell className="flex items-center gap-2 py-2">
        <TokenIcon symbol={asset} size="24" variant="branded" />
        {asset}
      </TableCell>
      <TableCell className="py-2" isNumeric>
        {formatNumber(walletBalance, 3)}
      </TableCell>
      <TableCell
        className={cn('py-2', { 'text-orange': hasUnsetTransfer || !entryTransfers.length })}
        isNumeric
      >
        {formatNumber(entryPrice, 3)} USD
      </TableCell>
      {/* <TableCell className="py-2" isNumeric>
        {formatNumber(unrealizedProfit)}
      </TableCell> */}
      <TableCell className="py-2" isNumeric>
        {formatNumber(marginBalance, 3)}
      </TableCell>
      {/* <TableCell className="py-2" isNumeric>
        {formatNumber(availableBalance)}
      </TableCell> */}
      <TableCell className="py-2" isNumeric>
        {formatNumber(assetContribution, 2)} USD
      </TableCell>
    </TableRow>
  );
};
