import { useCallback, useMemo, useState } from 'react';
import { DiamondPlus, X } from 'lucide-react';

import { StartStrategyModal } from '@/components/blocks/modals/StartStrategyModal/StartStrategyModal';
import { StrategyItem } from '@/components/blocks/StrategyItem/StrategyItem';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDashboardContext } from '@/context/DashboardContext';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePositions } from '@/hooks/usePositions';
import { useAllStrategies } from '@/hooks/useStrategies';
import { AnyStrategy } from '@/types';

export const StrategiesList = () => {
  const [tab, setTab] = useState<string>('active');
  const {
    activeStrategies,
    terminatedStrategies,
    stoppedStrategies,
    allStrategies,
    isLoading: isAllStrategiesLoading,
  } = useAllStrategies();
  const {
    positionsRequest: { isLoading: isPositionsLoading },
  } = usePositions();
  const { selectStrategy } = useDashboardContext();

  const { isOpen: isOpenStart, onClose: onCloseStart, onOpen: onOpenStart } = useDisclosure();
  const [search, setSearch] = useState<string>('');
  const [selectedStrategy, setSelectedStrategy] = useState<AnyStrategy>();

  const strategies = useMemo(() => {
    let strategiesList: AnyStrategy[] = [];
    if (search) {
      strategiesList = allStrategies.filter(
        strategy =>
          strategy.symbol.toLowerCase().includes(search.toLowerCase()) ||
          strategy.id.toString().includes(search)
      );
    } else if (tab === 'active') strategiesList = activeStrategies;
    else if (tab === 'terminated') strategiesList = terminatedStrategies;
    else if (tab === 'stopped') strategiesList = stoppedStrategies;

    return strategiesList.sort((a, b) => b.id - a.id);
  }, [search, tab, allStrategies, activeStrategies, terminatedStrategies, stoppedStrategies]);

  const isLoading = isAllStrategiesLoading || isPositionsLoading;

  const handleResetSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleStartStrategyByCopy = useCallback(
    (strategy: AnyStrategy) => {
      setSelectedStrategy(strategy);
      onOpenStart();
    },
    [onOpenStart]
  );
  const handleCloseStartModal = useCallback(() => {
    setSelectedStrategy(undefined);
    onCloseStart();
  }, [onCloseStart]);

  return (
    <div className="absolute inset-0 flex flex-col">
      {isLoading ? <CenteredSpinner /> : null}

      {isOpenStart ? (
        <StartStrategyModal
          isEdit={false}
          params={selectedStrategy}
          onClose={handleCloseStartModal}
        />
      ) : null}

      <div className="flex shrink-0 items-center px-3 pt-2">
        <div className="text-section-heading mr-3">Strategies</div>

        <Button size="icon" variant="ghost" className="size-8" onClick={onOpenStart}>
          <DiamondPlus className="size-6" />
        </Button>

        <div className="relative ml-auto flex w-[140px]">
          <Input
            variant="flushed"
            size="sm"
            type="text"
            placeholder="Symbol or id"
            value={search}
            onChange={val => setSearch(val.target.value)}
          />
          {search ? (
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-0 top-1 size-6"
              onClick={handleResetSearch}
            >
              <X className="size-4" />
            </Button>
          ) : null}
        </div>
      </div>

      <Tabs value={tab} onValueChange={setTab} variant="unstyled">
        <TabsList>
          <TabsTrigger value="active">Active ({activeStrategies?.length ?? '-'})</TabsTrigger>
          <TabsTrigger value="terminated">
            Terminated ({terminatedStrategies?.length ?? '-'})
          </TabsTrigger>
          <TabsTrigger value="stopped">Stopped ({stoppedStrategies?.length ?? '-'})</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="grow overflow-auto">
        {strategies.map(strategy => (
          <StrategyItem
            key={`${tab}-${strategy.id}`}
            strategy={strategy}
            withActions
            onStartCopy={() => handleStartStrategyByCopy(strategy)}
            onSymbolClick={() => selectStrategy(strategy)}
          />
        ))}

        {strategies.length == 0 ? <div className="mt-12 text-center">No strategies</div> : null}
      </div>
    </div>
  );
};
