import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { AnyStrategy, isKaeruStrategy, KlineIntervalEnum } from '@/types';

type DashboardContextType = {
  isTokenSize: boolean;
  interval: KlineIntervalEnum;
  activeSymbol: string;
  activeStrategy?: AnyStrategy;
  selectSymbol: (symbol?: string) => void;
  selectStrategy: (strategy?: AnyStrategy) => void;
  selectInterval: (interval: KlineIntervalEnum) => void;
  selectIsTokenSize: (val: boolean) => void;
};

export const DEFAULT_SYMBOL = 'BTCUSDT';

const DashboardContext = createContext({ activeSymbol: DEFAULT_SYMBOL } as DashboardContextType);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [activeSymbol, setActiveSymbol] = useState<string>(DEFAULT_SYMBOL);
  const [activeStrategy, setActiveStrategy] = useState<AnyStrategy>();
  const [interval, setIntervalValue] = useState<KlineIntervalEnum>(KlineIntervalEnum.ONE_HOUR);
  const [isTokenSize, setIsTokenSize] = useState<boolean>(false);

  const selectInterval = useCallback((val: KlineIntervalEnum) => {
    setIntervalValue(val);
  }, []);

  const selectSymbol = useCallback(
    (symbol?: string) => {
      setActiveSymbol(symbol || DEFAULT_SYMBOL);
      if (symbol && symbol !== activeStrategy?.symbol) {
        setActiveStrategy(undefined);
      }
    },
    [setActiveSymbol, setActiveStrategy, activeStrategy]
  );

  const selectStrategy = useCallback(
    (strategy?: AnyStrategy) => {
      setActiveStrategy(strategy);
      if (strategy) {
        setActiveSymbol(strategy.symbol);

        if (isKaeruStrategy(strategy)) {
          selectInterval(strategy.timeframe);
        }
      }
    },
    [setActiveSymbol, setActiveStrategy, selectInterval]
  );

  const selectIsTokenSize = useCallback((val: boolean) => {
    setIsTokenSize(val);
  }, []);

  const contextValue = useMemo(
    () => ({
      isTokenSize,
      interval,
      activeSymbol,
      activeStrategy,
      selectSymbol,
      selectStrategy,
      selectInterval,
      selectIsTokenSize,
    }),
    [
      isTokenSize,
      activeSymbol,
      activeStrategy,
      selectSymbol,
      selectStrategy,
      interval,
      selectInterval,
      selectIsTokenSize,
    ]
  );

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export default DashboardProvider;
