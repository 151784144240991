import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  GridStrategyInfoFields,
  KaeruStrategyInfoFields,
} from '@/components/blocks/StrategyInfoFields/StrategyInfoFields';
import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDashboardContext } from '@/context/DashboardContext';
import { useStrategyActions } from '@/hooks/useStrategies';
import {
  AnyStrategy,
  AnyStrategyCalculatedParams,
  GridStrategy,
  GridStrategyCalculatedParams,
  GridStrategyModeEnum,
  isGridStartStrategy,
  isKaeruStartStrategy,
  KaeruStrategy,
  KaeruStrategyCalculatedParams,
  KaeruStrategyModeEnum,
  KlineIntervalEnum,
  StartAnyStrategyParams,
  StartGridStrategyParams,
  StartKaeruStrategyParams,
  StrategyTypeEnum,
} from '@/types';

type StartStrategyModalProps = {
  isEdit: boolean;
  params?: AnyStrategy;
  onClose: () => void;
};
export const StartStrategyModal = ({ isEdit, params, onClose }: StartStrategyModalProps) => {
  const { calculateStrategyMutation } = useStrategyActions();
  const { startStrategyMutation, updateStrategyMutation } = useStrategyActions();

  const [validStrategyParams, setValidStrategyParams] = useState<StartAnyStrategyParams>();
  const [calculatedParams, setCalculatedParams] = useState<AnyStrategyCalculatedParams>();

  const [strategyType, setStrategyType] = useState<StrategyTypeEnum>(
    params?.type || StrategyTypeEnum.Grid
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSetStrategyType = useCallback((type: StrategyTypeEnum) => {
    setStrategyType(type);
    setValidStrategyParams(undefined);
    setCalculatedParams(undefined);
  }, []);

  const handleCalculate = useCallback(
    (strategyParams: StartAnyStrategyParams) => {
      setIsLoading(true);
      setError('');

      calculateStrategyMutation
        .mutateAsync({ type: strategyType, params: strategyParams })
        .then(res => {
          setValidStrategyParams(strategyParams);
          setCalculatedParams(res.data.params);
        })
        .catch(err => {
          setError(err.response.data.detail);
        })
        .finally(() => setIsLoading(false));
    },
    [calculateStrategyMutation, strategyType]
  );

  const handleStart = useCallback(() => {
    if (validStrategyParams) {
      setIsLoading(true);
      startStrategyMutation
        .mutateAsync({ type: strategyType, params: validStrategyParams })
        .then(() => onClose())
        .catch(err => {
          setError(err.response.data.detail);
        })

        .finally(() => setIsLoading(false));
    }
  }, [strategyType, validStrategyParams, startStrategyMutation, onClose]);

  const handleUpdate = useCallback(() => {
    if (validStrategyParams) {
      setIsLoading(true);

      const strategyId = params?.id;
      let updateParams = null;
      if (strategyType == StrategyTypeEnum.Grid && isGridStartStrategy(validStrategyParams)) {
        updateParams = {
          hedgeEnabled: validStrategyParams.hedgeEnabled,
          hedgeGap: validStrategyParams.hedgeGap,
        };
      } else if (
        strategyType == StrategyTypeEnum.Kaeru &&
        isKaeruStartStrategy(validStrategyParams)
      ) {
        updateParams = {
          timeframe: validStrategyParams.timeframe,
          rsiLength: validStrategyParams.rsiLength,
          smaLength: validStrategyParams.smaLength,
          tradeSize: validStrategyParams.tradeSize,
        };
      }

      if (strategyId && updateParams) {
        updateStrategyMutation
          .mutateAsync({ id: strategyId, params: updateParams })
          .then(() => onClose())
          .catch(err => {
            setError(err.response.data.detail);
          })
          .finally(() => setIsLoading(false));
      } else {
        setError('Unknown strategy params');
      }
    }
  }, [validStrategyParams, updateStrategyMutation, onClose, params, strategyType]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        aria-describedby="Start strategy dialog"
        className="flex max-h-[90vh] w-fit max-w-fit flex-col"
      >
        <DialogHeader>
          <DialogTitle>
            {isEdit ? `Update strategy (id: ${params?.id})` : 'Start strategy'}
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="flex gap-8">
          {isLoading ? <CenteredSpinner /> : null}

          <Tabs
            value={strategyType}
            variant="filled"
            className="flex grow flex-col items-start"
            onValueChange={val => val && handleSetStrategyType(val as StrategyTypeEnum)}
          >
            <TabsList className="p-0">
              {Object.entries(StrategyTypeEnum).map(([key, value]) => (
                <TabsTrigger value={value} key={key} disabled={isEdit}>
                  {value}
                </TabsTrigger>
              ))}
            </TabsList>

            <div className="mt-2 flex size-full grow flex-col">
              {strategyType == StrategyTypeEnum.Grid ? (
                <StartGridStrategyForm
                  isEdit={isEdit}
                  params={params as GridStrategy}
                  error={error}
                  onCalculate={handleCalculate}
                  onFormChange={() => setValidStrategyParams(undefined)}
                />
              ) : null}
              {strategyType == StrategyTypeEnum.Kaeru ? (
                <StartKaeruStrategyForm
                  isEdit={isEdit}
                  params={params as KaeruStrategy}
                  error={error}
                  onCalculate={handleCalculate}
                  onFormChange={() => setValidStrategyParams(undefined)}
                />
              ) : null}
            </div>
          </Tabs>

          {Boolean(calculatedParams) ? (
            <div className="flex w-[360px] flex-col">
              {strategyType === StrategyTypeEnum.Grid ? (
                <GridStrategyInfoFields {...(calculatedParams as GridStrategyCalculatedParams)} />
              ) : null}

              {strategyType === StrategyTypeEnum.Kaeru ? (
                <KaeruStrategyInfoFields {...(calculatedParams as KaeruStrategyCalculatedParams)} />
              ) : null}

              <div className="mt-auto">
                <Button
                  className="mt-4 w-full"
                  disabled={!validStrategyParams}
                  onClick={isEdit ? handleUpdate : handleStart}
                >
                  {isEdit ? 'Update strategy' : 'Start strategy'}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const gridFormSchema = z
  .object({
    mode: z.nativeEnum(GridStrategyModeEnum),
    symbol: z.string(),
    minPrice: z.coerce.number().positive(),
    maxPrice: z.coerce.number().positive(),
    step: z.coerce.number().min(0.1).max(5),
    leverage: z.coerce.number().min(1).max(25),
    multiplier: z.coerce.number().min(1).max(3),
    capital: z.coerce.number().optional(),
    hedgeEnabled: z.boolean(),
    hedgeGap: z.coerce.number().min(-30).max(30).optional(),
  })
  .refine(schema => schema.minPrice < schema.maxPrice, {
    message: 'Min price should be less than max price',
    path: ['minPrice'],
  })
  .refine(schema => !schema.hedgeEnabled || (schema.hedgeEnabled && schema.hedgeGap != undefined), {
    message: 'Hedge gap should be set if hedge is enabled',
    path: ['hedgeGap'],
  });

type StartGridStrategyFormProps = {
  isEdit: boolean;
  params?: GridStrategy;
  error?: string;
  onCalculate: (startParams: StartGridStrategyParams) => void;
  onFormChange: () => void;
};
const StartGridStrategyForm = ({
  isEdit,
  params,
  error,
  onCalculate,
  onFormChange,
}: StartGridStrategyFormProps) => {
  const { activeSymbol } = useDashboardContext();

  const form = useForm<z.infer<typeof gridFormSchema>>({
    resolver: async (data, context, options) => {
      onFormChange();
      return zodResolver(gridFormSchema)(data, context, options);
    },

    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      mode: params?.mode || GridStrategyModeEnum.normal,
      symbol: params?.symbol || activeSymbol,
      minPrice: params?.minPrice || 0,
      maxPrice: params?.maxPrice || 0,
      step: (params?.step || 0.0128) * 100,
      leverage: params?.leverage || 10,
      multiplier: params?.multiplier || 2,
      capital: params?.capital || undefined,
      hedgeEnabled: params?.hedgeEnabled || false,
      hedgeGap: params?.hedgeGap || undefined,
    },
  });

  const handleCalculate = useCallback(
    (strategyParams: z.infer<typeof gridFormSchema>) => {
      const params = {
        ...strategyParams,
        step: strategyParams.step / 100,
        hedgeGap: strategyParams.hedgeGap ? strategyParams.hedgeGap / 100 : strategyParams.hedgeGap,
      };
      onCalculate(params);
    },
    [onCalculate]
  );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleCalculate)}
        className="flex h-full w-[360px] flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="mode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Strategy mode</FormLabel>
              <FormControl>
                <Select
                  disabled={isEdit}
                  value={field.value}
                  name={field.name}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={field.value} onBlur={field.onBlur} ref={field.ref} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(GridStrategyModeEnum).map(([key, value]) => (
                      <SelectItem key={key} value={value}>
                        {value.replaceAll('_', ' ')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="symbol"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Symbol</FormLabel>
                <FormControl>
                  <SymbolSearchSelect
                    className="w-full"
                    symbol={field.value}
                    variant="outline"
                    disabled={isEdit}
                    onSetSymbol={val => field.onChange(val)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="step"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Step</FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="minPrice"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Min price</FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="maxPrice"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Max price</FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="leverage"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Leverage</FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="multiplier"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Capital multiplier</FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="capital"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>
                  Capital, USD <span className="text-third-foreground">(optional)</span>
                </FormLabel>
                <FormControl>
                  <Input disabled={isEdit} {...field} value={field.value || ''} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="hedgeEnabled"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormControl>
                  <Checkbox
                    ref={field.ref}
                    id="strategyHedgeEnabled"
                    label="Enable hedge order"
                    checked={field.value}
                    disabled={field.disabled}
                    onBlur={field.onBlur}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="hedgeGap"
            render={({ field }) =>
              form.getValues().hedgeEnabled ? (
                <FormItem className="basis-1/2">
                  <FormLabel>Hedge gap, %</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              ) : null
            }
          />
        </div>
        {form.getValues().hedgeEnabled ? (
          <div className="text-xs text-secondary-foreground">
            Specify gap under min price at which hedge should be placed.
            <br />
            STOP_MARKET order with max strategy position will be placed. <br />
            5% - means 5% under min price
            <br />
            -5% - means 5% over min price
          </div>
        ) : null}

        <FormMessage>{error}</FormMessage>

        <div className="ml-auto mt-auto">
          <Button type="submit">Calculate</Button>
        </div>
      </form>
    </Form>
  );
};

const kaeruFormSchema = z.object({
  mode: z.nativeEnum(KaeruStrategyModeEnum),
  symbol: z.string(),
  timeframe: z.nativeEnum(KlineIntervalEnum),
  rsiLength: z.coerce.number().positive(),
  smaLength: z.coerce.number().positive(),
  tradeSize: z.coerce.number().positive(),
});

type StartKaeruStrategyFormProps = {
  isEdit: boolean;
  params?: KaeruStrategy;
  error?: string;
  onCalculate: (startParams: StartKaeruStrategyParams) => void;
  onFormChange: () => void;
};
const StartKaeruStrategyForm = ({
  isEdit,
  params,
  error,
  onCalculate,
  onFormChange,
}: StartKaeruStrategyFormProps) => {
  const { activeSymbol } = useDashboardContext();

  const form = useForm<z.infer<typeof kaeruFormSchema>>({
    resolver: async (data, context, options) => {
      onFormChange();
      return zodResolver(kaeruFormSchema)(data, context, options);
    },

    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      mode: params?.mode || KaeruStrategyModeEnum.Long,
      symbol: params?.symbol || activeSymbol,
      timeframe: params?.timeframe || KlineIntervalEnum.FOUR_HOUR,
      rsiLength: params?.rsiLength || undefined,
      smaLength: params?.smaLength || undefined,
      tradeSize: params?.tradeSize || undefined,
    },
  });

  const handleCalculate = useCallback(
    (strategyParams: z.infer<typeof kaeruFormSchema>) => {
      onCalculate(strategyParams);
    },
    [onCalculate]
  );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleCalculate)}
        className="flex h-full w-[360px] flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="mode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Strategy mode</FormLabel>
              <FormControl>
                <Select
                  disabled={isEdit}
                  value={field.value}
                  name={field.name}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={field.value} onBlur={field.onBlur} ref={field.ref} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(KaeruStrategyModeEnum).map(([key, value]) => (
                      <SelectItem key={key} value={value}>
                        {value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="symbol"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>Symbol</FormLabel>
                <FormControl>
                  <SymbolSearchSelect
                    disabled={isEdit}
                    className="w-full"
                    symbol={field.value}
                    variant="outline"
                    onSetSymbol={val => field.onChange(val)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timeframe"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Timeframe</FormLabel>
                <FormControl>
                  <Select value={field.value} name={field.name} onValueChange={field.onChange}>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={field.value}
                        onBlur={field.onBlur}
                        ref={field.ref}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(KlineIntervalEnum).map(([key, value]) => (
                        <SelectItem key={key} value={value}>
                          {value}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="rsiLength"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>RSI length</FormLabel>
                <FormControl>
                  <Input {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="smaLength"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>SMA length</FormLabel>
                <FormControl>
                  <Input {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-start gap-3">
          <FormField
            control={form.control}
            name="tradeSize"
            render={({ field }) => (
              <FormItem className="basis-1/2">
                <FormLabel>
                  Trade size <span className="text-third-foreground">(tokens)</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} type="number" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormMessage>{error}</FormMessage>

        <div className="ml-auto mt-auto">
          <Button type="submit">Calculate</Button>
        </div>
      </form>
    </Form>
  );
};
