import { useCallback, useMemo } from 'react';

import { CenteredSpinner } from '@/components/ui/spinner';
import { Table, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { TableBody } from '@/components/ui/table';
import { TableCell } from '@/components/ui/table';
import { useDashboardContext } from '@/context/DashboardContext';
import { usePositions } from '@/hooks/usePositions';
import { formatNumber } from '@/lib/common';
import { cn } from '@/lib/utils';
import { Position, PositionSideEnum } from '@/types';

export const PositionsList = () => {
  const {
    positions,
    positionsRequest: { isLoading },
  } = usePositions();

  const { isTokenSize, selectIsTokenSize } = useDashboardContext();

  const togglePositionSize = useCallback(() => {
    selectIsTokenSize(!isTokenSize);
  }, [isTokenSize, selectIsTokenSize]);

  return (
    <>
      {isLoading ? <CenteredSpinner /> : null}

      {!positions.length ? (
        <div className="my-8 text-center text-base text-third-foreground">
          {isLoading ? 'Loading' : `No positions found`}
        </div>
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Symbol</TableHead>
              <TableHead isNumeric onClick={togglePositionSize} className="cursor-pointer">
                {isTokenSize ? 'Tokens' : 'Size, $'}
              </TableHead>
              <TableHead isNumeric>Entry price</TableHead>
              <TableHead isNumeric>Mark price</TableHead>
              <TableHead isNumeric>Liq. price</TableHead>
              <TableHead isNumeric>Margin</TableHead>
              <TableHead isNumeric>PNL</TableHead>
              <TableHead isNumeric>Break price</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {positions.map((position, index) => (
              <PositionRow key={`${position.symbol}-${index}`} {...position} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

type PositionRowProps = Position;
const PositionRow = ({
  symbol,
  positionAmt,
  positionSide,
  leverage,
  liquidationPrice,
  markPrice,
  entryPrice,
  notional,
  unRealizedProfit,
  breakEvenPrice,
}: PositionRowProps) => {
  const { selectSymbol } = useDashboardContext();

  const { isTokenSize } = useDashboardContext();

  const positionSideColor = useMemo(() => {
    if (positionSide === PositionSideEnum.SHORT) return 'border-sell';
    return 'border-buy';
  }, [positionSide]);
  const profitColor = useMemo(() => {
    if (unRealizedProfit < 0) return 'text-red';
    return 'text-green';
  }, [unRealizedProfit]);

  return (
    <TableRow className="font-normal">
      <TableCell className="px-4 py-2">
        <div
          className={cn('cursor-pointer border-l-2 pl-1', positionSideColor)}
          onClick={() => selectSymbol(symbol)}
        >
          <p className="font-medium">{symbol}</p>
          <p className="text-xs">
            {positionSide} <span className="text-third-foreground">x{leverage}</span>
          </p>
        </div>
      </TableCell>
      <TableCell isNumeric>
        {isTokenSize ? formatNumber(positionAmt) : formatNumber(notional, 2)}
      </TableCell>
      <TableCell isNumeric>{formatNumber(entryPrice, 3)}</TableCell>
      <TableCell isNumeric>{formatNumber(markPrice, 3)}</TableCell>
      <TableCell isNumeric className="text-orange">
        {liquidationPrice ? formatNumber(liquidationPrice, 3) : '--'}
      </TableCell>
      <TableCell isNumeric>{formatNumber(Math.abs(notional) / leverage, 2)} USDT</TableCell>
      <TableCell isNumeric className={profitColor}>
        {formatNumber(unRealizedProfit, 2)} USDT
      </TableCell>
      <TableCell isNumeric>{formatNumber(breakEvenPrice, 3)}</TableCell>
    </TableRow>
  );
};
