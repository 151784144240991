import * as React from 'react';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { FileDown } from 'lucide-react';

import { StrategyItem } from '@/components/blocks/StrategyItem/StrategyItem';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { DatePickerWithRange } from '@/components/ui/date-range-picker';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  DAY_PERIOD,
  MONTH_PERIOD,
  useReport,
  useStartTime,
  useStatus,
  WEEK_PERIOD,
} from '@/hooks/useStatistic';
import { getNowTimestamp } from '@/lib/common';
import { StrategyStateEnum } from '@/types';

import { PortfolioReportChart } from './PortfolioReportChart';
import { StrategiesReportCharts } from './StrategiesReportCharts';
import { SymbolsReportChart } from './SymbolsReportChart';

const PERIODS = [
  {
    label: 'Last day',
    delta: DAY_PERIOD,
  },
  {
    label: 'Last 7 days',
    delta: WEEK_PERIOD,
  },
  {
    label: 'Last 30 days',
    delta: MONTH_PERIOD,
  },
];

export const PeriodReport = () => {
  const [nowTs] = React.useState<number>(getNowTimestamp());
  const [statisticPage, setStatisticPage] = React.useState<string>('portfolio');
  const [periodLabel, setPeriodLabel] = React.useState(PERIODS[0].label);
  const [showTerminated, setShowTerminated] = React.useState(true);
  const [showStopped, setShowStopped] = React.useState(false);
  const [customDateRange, setCustomDateRange] = React.useState<DateRange | undefined>();

  const { startTs } = useStartTime();

  const reportFromTs = useMemo(() => {
    if (periodLabel === 'custom' && customDateRange?.from) {
      return customDateRange.from.getTime();
    }
    if (periodLabel === 'start' && startTs) {
      return startTs;
    }
    const period = PERIODS.find(p => p.label === periodLabel);
    return nowTs - (period?.delta || 0);
  }, [periodLabel, nowTs, startTs, customDateRange]);

  const reportToTs = useMemo(() => {
    if (periodLabel === 'custom' && customDateRange?.to) {
      const customToDate = new Date(customDateRange.to);
      customToDate.setHours(23, 59, 59);
      const customToTs = customToDate.getTime();
      return customToTs < nowTs ? customToTs : undefined;
    }
    return undefined;
  }, [periodLabel, customDateRange, nowTs]);

  const {
    aggregatedTransfers,
    netProfit,
    funding,
    fee,
    tradesStat,
    strategies,
    exportReportMutation,
    reportRequest: { data: reportData, isLoading: isReportLoading },
  } = useReport(reportFromTs, reportToTs);
  const { currentStrategies } = useStatus();

  const strategiesList = useMemo(() => {
    const activeStrategies = strategies.filter(
      strategy => strategy.state == StrategyStateEnum.Active
    );
    const terminatedStrategies = strategies.filter(
      strategy => strategy.state == StrategyStateEnum.Terminated
    );
    const stoppedStrategies = strategies.filter(
      strategy => strategy.state == StrategyStateEnum.Stopped
    );

    let activeAndTerminated = [
      ...activeStrategies,
      ...(showTerminated ? terminatedStrategies : []),
    ];
    // if until current time
    if (!reportToTs) {
      activeAndTerminated = activeAndTerminated.map(strategy => ({
        ...strategy,
        tradeBalance: currentStrategies.find(s => s.id === strategy.id)?.tradeBalance || 0,
      }));
    }

    return [...activeAndTerminated, ...(showStopped ? stoppedStrategies : [])];
  }, [strategies, showStopped, showTerminated, reportToTs, currentStrategies]);

  const handleExport = React.useCallback(() => {
    exportReportMutation.mutateAsync({ fromTs: reportFromTs, toTs: reportToTs });
  }, [exportReportMutation, reportFromTs, reportToTs]);

  return (
    <div className="flex h-[720px] grow gap-4">
      <div className="flex max-w-screen-xl grow flex-col gap-4">
        {/* Header */}
        <div className="flex items-center justify-between">
          <Tabs variant="filled" value={periodLabel} onValueChange={value => setPeriodLabel(value)}>
            <TabsList className="gap-1">
              {PERIODS.map(period => (
                <TabsTrigger key={period.label} value={period.label}>
                  {period.label}
                </TabsTrigger>
              ))}
              <TabsTrigger value="start" disabled={!startTs}>
                All time
              </TabsTrigger>
              <TabsTrigger value="custom" className="p-0" asChild>
                <DatePickerWithRange onSelect={setCustomDateRange} />
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Button
            variant="ghost"
            className="ml-auto mr-2 text-third-foreground"
            size="xs"
            isLoading={exportReportMutation.isPending}
            onClick={handleExport}
          >
            <FileDown className="mr-1 size-4" /> Export
          </Button>

          <Tabs
            variant="filled"
            value={statisticPage}
            onValueChange={value => setStatisticPage(value)}
          >
            <TabsList className="gap-1">
              <TabsTrigger value="portfolio">Portfolio</TabsTrigger>
              <TabsTrigger value="strategies">Strategies</TabsTrigger>
              <TabsTrigger value="symbols">Tickers</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        {/* Body */}
        <div className="relative flex grow gap-4">
          {isReportLoading ? <CenteredSpinner /> : null}

          {/* <div className="width-[720px]"> */}
          {statisticPage == 'portfolio' ? (
            <PortfolioReportChart
              aggregatedTransfers={aggregatedTransfers}
              netProfit={netProfit}
              funding={funding}
              fee={fee}
              tradesStat={tradesStat}
              reportData={reportData}
              fromTs={reportFromTs}
              toTs={reportToTs || nowTs}
            />
          ) : null}
          {statisticPage === 'strategies' ? (
            <StrategiesReportCharts
              reportData={reportData}
              fromTs={reportFromTs}
              toTs={reportToTs || nowTs}
            />
          ) : null}
          {statisticPage === 'symbols' ? (
            <SymbolsReportChart
              reportData={reportData}
              fromTs={reportFromTs}
              toTs={reportToTs || nowTs}
            />
          ) : null}
          {/* </div> */}
        </div>
      </div>

      <div className="flex w-[420px] shrink-0 grow-0 flex-col items-end gap-4 border-l border-border pl-4">
        <div className="flex items-center gap-2">
          <Checkbox
            id="show-terminated"
            label="Terminated"
            checked={showTerminated}
            onCheckedChange={val => setShowTerminated(Boolean(val))}
          />
          <Checkbox
            id="show-stopped"
            label="Stopped"
            checked={showStopped}
            onCheckedChange={val => setShowStopped(Boolean(val))}
          />
        </div>

        <div className="flex w-full flex-col overflow-auto ">
          {strategiesList.length === 0 ? (
            <p className="my-8 text-center text-third-foreground">No strategies</p>
          ) : null}

          {strategiesList.map(strategy => (
            <StrategyItem key={strategy.id} strategy={strategy} withActions={false} />
          ))}
        </div>
      </div>
    </div>
  );
};
