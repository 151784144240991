export enum StrategyStateEnum {
  Active = 'Active',
  Terminated = 'Terminated',
  Stopped = 'Stopped',
}
export enum StrategyTypeEnum {
  Grid = 'Grid',
  Kaeru = 'Kaeru',
}

export type StrategyBase = {
  id: number;
  symbol: string;
  startTimestamp: number;
  stopTimestamp?: number;
  state: StrategyStateEnum;
  type: StrategyTypeEnum;
  tradeBalance?: number;
};

type StrategyBaseWithCommission = StrategyBase & {
  operationTrades: number;
  operationProfit: number;
  totalTrades: number;
  totalFees: number;
  totalPnl: number;
  currentPnl?: number;
};

export enum GridStrategyModeEnum {
  normal = 'normal',
  stopOnTop = 'stop_on_top',
  reduceEntryPrice = 'reduce_entry_price',
}
export enum KaeruStrategyModeEnum {
  Long = 'Long',
  Short = 'Short',
}
export enum KaeruSignalEnum {
  SELL_SIGNAL = 'sell',
  BUY_SIGNAL = 'buy',
}
export enum StrategyStopModeEnum {
  CANCEL_ORDERS_AND_STOP = 'cancel-and-stop',
  SELL_STRATEGY_POSITION_AND_STOP = 'sell-strategy-and-stop',
  SELL_ALL_POSITION_AND_STOP = 'sell-all-and-stop',
  TERMINATE = 'terminate',
}

export enum PositionSideEnum {
  LONG = 'LONG',
  SHORT = 'SHORT',
}
export enum SideEnum {
  BUY = 'BUY',
  SELL = 'SELL',
}
export enum OrderStatusEnum {
  NEW = 'NEW',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  EXPIRED_IN_MATCH = 'EXPIRED_IN_MATCH',
}

export enum KlineIntervalEnum {
  ONE_MIN = '1m',
  FIVE_MIN = '5m',
  FIFTEEN_MIN = '15m',
  ONE_HOUR = '1h',
  FOUR_HOUR = '4h',
  SIX_HOURS = '6h',
  EIGHT_HOURS = '8h',
  TWELVE_HOUR = '12h',
  ONE_DAY = '1d',
  THREE_DAY = '3d',
  ONE_WEEK = '1w',
  ONE_MONTH = '1M',
}

export enum OrderTypeEnum {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_MARKET = 'STOP_MARKET',
  STOP_LIMIT = 'STOP',
  // TAKE_PROFIT = "TAKE_PROFIT"
  // TAKE_PROFIT_MARKET = "TAKE_PROFIT_MARKET"
  // TRAILING_STOP_MARKET = "TRAILING_STOP_MARKET"
}

export enum IncomeTypeEnum {
  REALIZED_PNL = 'REALIZED_PNL',
  FUNDING_FEE = 'FUNDING_FEE',
  COMMISSION = 'COMMISSION',
  TRANSFER = 'TRANSFER',
  // Unused
  WELCOME_BONUS = 'WELCOME_BONUS',
  INSURANCE_CLEAR = 'INSURANCE_CLEAR',
  REFERRAL_KICKBACK = 'REFERRAL_KICKBACK',
  COMMISSION_REBATE = 'COMMISSION_REBATE',
  API_REBATE = 'API_REBATE',
  CONTEST_REWARD = 'CONTEST_REWARD',
  CROSS_COLLATERAL_TRANSFER = 'CROSS_COLLATERAL_TRANSFER',
  OPTIONS_PREMIUM_FEE = 'OPTIONS_PREMIUM_FEE',
  OPTIONS_SETTLE_PROFIT = 'OPTIONS_SETTLE_PROFIT',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  AUTO_EXCHANGE = 'AUTO_EXCHANGE',
  DELIVERED_SETTELMENT = 'DELIVERED_SETTELMENT',
  COIN_SWAP_DEPOSIT = 'COIN_SWAP_DEPOSIT',
  COIN_SWAP_WITHDRAW = 'COIN_SWAP_WITHDRAW',
  POSITION_LIMIT_INCREASE_FEE = 'POSITION_LIMIT_INCREASE_FEE',
}

export enum WorkingTypeEnum {
  MARK_PRICE = 'MARK_PRICE',
  CONTRACT_PRICE = 'CONTRACT_PRICE',
}
export enum TimeInForceEnum {
  GTC = 'GTC', // Good Till Cancel(GTC order valitidy is 1 year from placement)
  IOC = 'IOC', // Immediate or Cancel
  FOK = 'FOK', // Fill or Kill
  GTX = 'GTX', // Good Till Crossing (Post Only)
  GTD = 'GTD', // Good Till Date
}

export type OrderCreateParams = {
  symbol: string;
  type: OrderTypeEnum;
  positionSide: PositionSideEnum;
  side: SideEnum;
  qty: number;
  price?: number;
  stopPrice?: number;
  workingType?: WorkingTypeEnum;
  timeInForce?: TimeInForceEnum;
};

export type StartGridStrategyParams = {
  mode: GridStrategyModeEnum;
  symbol: string;
  minPrice: number;
  maxPrice: number;
  step: number;
  leverage: number;
  multiplier: number;
  capital?: number;
  hedgeEnabled: boolean;
  hedgeGap?: number;
};
export type StartKaeruStrategyParams = {
  mode: KaeruStrategyModeEnum;
  symbol: string;
  timeframe: KlineIntervalEnum;
  rsiLength: number;
  smaLength: number;
  tradeSize: number;
};
export type UpdateGridStrategyParams = {
  hedgeEnabled: boolean;
  hedgeGap?: number;
};
export type UpdateKaeruStrategyParams = {
  timeframe: KlineIntervalEnum;
  rsiLength: number;
  smaLength: number;
  tradeSize: number;
};

export type StartAnyStrategyParams = StartGridStrategyParams | StartKaeruStrategyParams;
export type UpdateAnyStrategyParams = UpdateGridStrategyParams | UpdateKaeruStrategyParams;

// GridStrategyWithCommission
export type GridStrategy = StrategyBaseWithCommission & {
  capital: number;
  gridLevel: number;
  initialPositionPrice: number;
  leverage: number;
  maxPrice: number;
  minPrice: number;
  mode: GridStrategyModeEnum;
  multiplier: number;
  step: number;
  strategyStartPrice: number;
  tradeSize: number;
  hedgeEnabled: boolean;
  hedgeGap?: number;
};
// KaeruStrategyWithCommission
export type KaeruStrategy = StrategyBaseWithCommission & {
  mode: KaeruStrategyModeEnum;
  timeframe: KlineIntervalEnum;
  rsiLength: number;
  smaLength: number;
  tradeSize: number;
  inTradeNow: boolean;
  lastTrade?: TradeExtended;
};
export type GridStrategyCalculatedParams = {
  symbol: string;
  mode: GridStrategyModeEnum;
  minPrice: number;
  maxPrice: number;
  currentPrice: number;
  step: number;
  initialCapital: number;
  leverage: number;
  multiplier: number;
  tradeSize: number;
  levels: number[];
  maxLevel: number;
  priceTickSize: number;
  lotStepSize: number;
  activeCapital: number;
  securityCapital: number;
  leveragedCapital: number;
  closestLevel: number;
  levelsForInvestment: number;
  priceForLongTrades: number;
  priceForInvestmentPosition: number;
  maxPositionSize: number;
  totalMaxSpent: number;
  capitalUsage: number;
  currentPosition: number;
  remainingPosition: number;
  positionForInvestment: number;
  minTradeProfit: number;
  maxTradeProfit: number;
  hedgeEnabled: boolean;
  hedgeGap?: number;
};

export type IndicatorValue = { time: number; value: number | null };

export type BacktestResult = {
  startTime: number;
  endTime: number;
  operationTrades: number;
  operationProfit: number;
  fees: number;
  profitableTrades: number;
  lossTrades: number;
  avgTrade: number; // percent
  avgTradeDuration: number; // ms
};

export type KaeruBacktestSignal = {
  time: number;
  signal: KaeruSignalEnum;
  currentRsi: number;
  currentSma: number;
  openPrice: number;
};

export type KaeruBacktestResults = BacktestResult & {
  rsi: IndicatorValue[];
  sma: IndicatorValue[];
  signals: KaeruBacktestSignal[];
};

export type KaeruStrategyCalculatedParams = StartKaeruStrategyParams & {
  maxMarketTradeSize: number;
  klinesLength: number;
  lotStepSize: number;
  lastSignal: KaeruSignalEnum;
  backtest: KaeruBacktestResults;
};

export type AnyStrategyCalculatedParams =
  | GridStrategyCalculatedParams
  | KaeruStrategyCalculatedParams;

export type AnyStrategy = GridStrategy | KaeruStrategy;

export type Position = {
  breakEvenPrice: number;
  entryPrice: number;
  leverage: number;
  liquidationPrice: number;
  markPrice: number;
  maxNotionalValue: number;
  notional: number;
  positionAmt: number;
  positionSide: PositionSideEnum;
  symbol: string;
  unRealizedProfit: number;
  updateTime: number;
};

export type Balance = {
  dt: string;
  marginBalance: number;
  availableBalance: number;
  totalBalance: number;
  unrealizedProfit: number;
  walletBalance: number;
};

export type Asset = {
  asset: string; // asset name
  walletBalance: number; // wallet balance
  unrealizedProfit: number; // unrealized profit
  marginBalance: number; // margin balance
  maintMargin: number; // maintenance margin required
  initialMargin: number; // total initial margin required with current mark price
  positionInitialMargin: number; // initial margin required for positions with current mark price
  openOrderInitialMargin: number; // initial margin required for open orders with current mark price
  crossWalletBalance: number; // crossed wallet balance
  crossUnPnl: number; // unrealized profit of crossed positions
  availableBalance: number; // available balance
  maxWithdrawAmount: number; // maximum amount for transfer out
  updateTime: number; // last update time
};

export type AssetIndex = {
  symbol: string;
  time: number;
  index: number;
  bidBuffer: number;
  askBuffer: number;
  bidRate: number;
  askRate: number;
  autoExchangeBidBuffer: number;
  autoExchangeAskBuffer: number;
  autoExchangeBidRate: number;
  autoExchangeAskRate: number;
};

export type Account = {
  totalInitialMargin: number;
  totalMaintMargin: number;
  totalWalletBalance: number;
  totalUnrealizedProfit: number;
  totalMarginBalance: number;
  totalPositionInitialMargin: number;
  totalOpenOrderInitialMargin: number;
  totalCrossWalletBalance: number;
  totalCrossUnPnl: number;
  availableBalance: number;
  maxWithdrawAmount: number;

  assets: Asset[];
  // positions: list[PositionCommonInfoType]
};

export type Income = {
  id: number;
  incomeType: IncomeTypeEnum;
  serverTimestamp: number;
  symbol?: string;
  income: number;
  asset?: string;
  transactionId: number;
  tradeId?: number;
  info?: string;
};

export type CommissionStat = {
  symbol: string;
  totalFunding: number;
  totalFee: number;
  totalPnl: number;
};

export type MarginRatios = {
  optimistic?: number;
  pessimistic?: number;
  liquidationPercentDelta?: number;
  currentValue?: number;
  grades: Record<number | string, number>;
};

export type SystemStatusResponse = {
  startTime: number;
  strategies: AnyStrategy[];
  commissionsStat: CommissionStat[];
  positions: Record<string, Position>;
  account: Account;
  marginRatios: MarginRatios;
};

export type Transfer = {
  id: number;
  dt: Date;
  asset: string;
  income: number;
  entryPrice?: number;
};

export type AdlQuantile = {
  symbol: string;
  adlQuantile: { LONG: number; SHORT: number };
};

export type Trade = {
  tradeId: number;
  orderId: number;
  symbol: string;
  serverTimestamp: number;
  positionSide: PositionSideEnum;
  side: SideEnum;
  type: OrderTypeEnum;
  avgPrice: number;
  qty: number;
  commission: number;
  realizedProfit: number;
};

type GridTrade = {
  tradeId: number;
  strategyId: number;
  operationProfit: number;
  level?: number;
};
type KaeruTrade = {
  tradeId: number;
  strategyId: number;
  operationProfit: number;
};

export type TradeExtended = Trade & Partial<GridTrade> & Partial<KaeruTrade>;

export type ReportResponse = {
  strategies: AnyStrategy[];
  balances: Balance[];
  transfers: Transfer[];
  incomes: Income[];
  trades: TradeExtended[];
};

export type TradeBalance = {
  id: number;
  symbol: string;
  dt: Date;
  tradeBalance: number;
  historyTradeBalance: number;
};

export type Kline = {
  openTs: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  closeTs: number;
};

export type Order = {
  symbol: string;
  avgPrice: number;
  clientOrderId: string;
  cumQuote: number;
  executedQty: number;
  orderId: number;
  origQty: number;
  origType: OrderTypeEnum;
  type: OrderTypeEnum;
  price: number;
  side: SideEnum;
  positionSide: PositionSideEnum;
  status: OrderStatusEnum;
  timeInForce: TimeInForceEnum;
  stopPrice: number;
  reduceOnly: boolean;
  time: number;
  updateTime: number;
  workingType: WorkingTypeEnum;
};

export function isGridStrategy(params: AnyStrategy): params is GridStrategy {
  return 'step' in params;
}
export function isKaeruStrategy(params: AnyStrategy): params is KaeruStrategy {
  return 'rsiLength' in params;
}
export function isGridStartStrategy(
  params: StartAnyStrategyParams
): params is StartGridStrategyParams {
  return 'step' in params;
}
export function isKaeruStartStrategy(
  params: StartAnyStrategyParams
): params is StartKaeruStrategyParams {
  return 'rsiLength' in params;
}
export function isGridCalculatedParams(
  params: AnyStrategyCalculatedParams
): params is GridStrategyCalculatedParams {
  return 'step' in params;
}
export function isKaeruCalculatedParams(
  params: AnyStrategyCalculatedParams
): params is KaeruStrategyCalculatedParams {
  return 'rsiLength' in params;
}
